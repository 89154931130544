.slider-containerw {
    width: 75%; /* Adjust width as needed */
    height: 600px; /* Adjust height as needed */
  }
  
  .slidew {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    transition: transform 0.5s ease, opacity 0.5s ease;
  }
  
  /* Center image styling */
  .swiper-slide-active .slidew {
    transform: scale(1);
    opacity: 1;
    filter: none; /* Remove blur for focused image */
    z-index: 2;
  }
  
  /* Previous and next images styling */
  .swiper-slide-prev .slidew,
  .swiper-slide-next .slidew {
    transform: scale(0.8); /* Slightly smaller */
    opacity: 0.5; /* Faded out */
    filter: blur(3px); /* Add blur */
    z-index: 1;
  }
  