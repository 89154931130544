.custom-footer {
  width: 100%;
  height: 565px; /* Let the height adjust dynamically */
  background-color: #f8f9fa;
  background-image: url('/public/img/Light/Footer.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
  padding: 20px; /* Add padding for better spacing */
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
}
.mobileNav{
  display: none;
}
.NavWeb{
  display: block;
}
@media (max-width: 760px) {
  .custom-footer {
      height: auto; /* Allow height to adapt on smaller screens */
      background-size: contain; /* Change to 'contain' for smaller devices */
  }
  .NavWeb{
    display: none;
  }
  .mobileNav{
    display: block;
  }
}
.list-unstyled a {
  font-size: 14px; /* Increase font size for readability */
  font-weight: 400;
  width: max-content;
  color: rgba(255, 255, 255, 0.8) ;
  margin: 5px; /* Add spacing between links */
  text-decoration: none; /* Ensure no underlines */
  display: block;
  float: left; /* Stack links vertically on small screens */
}

@media (max-width: 760px) {
  .list-unstyled a {
      font-size: 8px;
      margin: auto; 
  }
}

.enLink , .titleh5{
  text-align: left !important;
  margin: auto;
  margin-right: 20px;
  margin-left: 20px;
  
  }
.arLink {
  text-align: right !important;
  direction: rtl !important;
  font-family: 'Cairo', sans-serif;
  width: max-content;
   margin: 5px;
    margin-right: 20px;
    margin-left: 20px;
}

@media (max-width: 760px) {
  .arLink, .enLink {
      font-size: 8px; /* Adjust font size */
      margin: auto;
      margin-right: unset;
      margin-left: unset;
  }
}


  .disLinks{
color: rgb(203, 201, 201) !important;

  }
  /* .footer-content {
    text-align: center;
    padding: 20px;
    margin-top: 50px;
  }
  .footer-row{
    width: 100%;
    max-width: unset;
    font-size: 20px;
  } */
  .footer-content {
    text-align: center;
    padding: 20px;
    margin-top: 20px; /* Adjust margin for better spacing */
}

.footer-row {
    display: flex; /* Enable flexbox */
    align-items: center;
    text-align: center;
    max-width: unset;
    flex-wrap: nowrap;
}
.logo{
  max-width: 150px;
}
@media (max-width: 760px) {
  .footer-content {
    padding: 0;
  }
    .footer-row {
        flex-direction: row; /* Align elements horizontally on larger screens */
        justify-content: space-between;
        flex-wrap: nowrap;
    }
    .logo{
      max-width: 65px;
    }
}

  .form-control{
    background-color: transparent;
    font-size: 12px;
    font-weight: 400;
  }
  .form-control:focus{
    background-color: transparent;
  }
  .btn-light{
    background-color: transparent;
    color: white;
    font-size: 12px;
    font-weight: 400;
  }
  .form-text{
    color: white;
    font-size: 10px;
    font-weight: 400;
  }
  .custom-footer p {
    margin: 0;
    font-size: 16px;
  }
  h5{
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 10px;
  }

  .form-group{
    font-size: 12px;
    font-weight: 400;
  }
  @media (max-width: 760px) {
    p {
        font-size: 12px;
        font-family: "Cairo"; /* Adjust heading and paragraph sizes */
    }
    .titleh5{
      font-size: 8px;
      margin: auto;
      margin-right: unset;
      margin-left: unset;
    }

    .form-control,
    .btn-light {
        font-size: 10px; /* Reduce form and button text size */
    }

 
}
.footer-links {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  gap: 10px;
  justify-content: center; /* Center align on smaller screens */
}

@media (min-width: 760px) {
  .footer-links {
      justify-content: space-between; /* Distribute evenly on larger screens */
  }
}
.social-icon{
  display: inline-block;
    width: 40px !important;
    height: 40px !important;
    position: relative;
    overflow: hidden;
    vertical-align: middle;
    margin: 2px !important;
}
  .footer-left {
    position: absolute; /* Sticks the div to a fixed location on the screen */
    bottom: 0; /* Positions it at the bottom */
    left: 0; /* Positions it at the left */
    padding: 10px; /* Optional padding for some spacing */
    font-size: 14px; /* Adjust font size as needed */
    background-color: transparent; /* Optional, set background color if needed */
    color: #FFF; /* Adjust text color */
  }
  @media (max-width: 760px) {
    .footer-left {
      padding: 5px;
    }
    .footer-left p {
      font-size: 5px !important; 
    }
    .social-icon{
      display: inline-block;
        width: 30px !important;;
        height: 30px !important;;
        position: relative;
        overflow: hidden;
        vertical-align: middle;
        margin: 2px !important;
    }
  
  }

  @media (max-width: 360px) {
    .custom-footer {
      padding: 10px;
    }
  }