/* Base styles for the container */
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 1rem;
  padding-bottom: 1rem;
}

@media (min-width: 768px) {
  .container {
    padding: 0;
  }
}

/* Heading styles */
.heading {
  max-width: 80rem;
  font-family: 'Sans-serif', Arial, sans-serif;
  font-weight: 600;
  
}

.heading span.green {
  color: #10b981; /* Emerald */
}

.heading span.black {
  color: #000; /* Black */
}

@media (max-width: 640px) {
  .heading {
    font-size: 1rem; /* sm:text-3xl */
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 1.5rem; /* md:text-4xl */
  }
}

/* Subtitle styles */
.subtitle {
  font-size: 1rem;
}

@media (max-width: 768px) {
  .subtitle {
    font-size: 0.8rem; /* md:text-base */
  }
}


.section-container {
    margin-bottom: 10rem; /* Default margin-bottom for medium screens */
  }

  /* Medium screens (e.g., tablets) */
@media (min-width: 768px) and (max-width: 1423px) {
    .section-container {
      margin-bottom: 6rem; /* Specific value for medium screens */
    }
  }
  
  /* Increase margin-bottom for larger screens */
  @media (min-width: 1424px) {
    .section-container {
      margin-bottom: 15rem; /* Increased margin-bottom for large screens */
    }
  } 
  
  /* Decrease margin-bottom for smaller screens */
  @media (max-width: 768px) {
    .section-container {
      margin-bottom: -14rem; /* Reduced margin-bottom for smaller screens */
    }
  }
  