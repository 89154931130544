/* Base styles for the container */
.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 1rem;
  }
  
  
  @media (min-width: 768px) {
    .container {
      padding: 0;
    }
  }
  
  
  /* Heading styles */
  .heading {
    max-width: 80rem;
    font-family: 'Sans-serif', Arial, sans-serif;
    font-weight: 600;
    
  }
  
  .heading span.green {
    color: #10b981; /* Emerald */
  }
  
  .heading span.black {
    color: #000; /* Black */
  }
  
  @media (max-width: 640px) {
    .heading {
      font-size: 1rem; /* sm:text-3xl */
    }
  }
  
  @media (max-width: 768px) {
    .heading {
      font-size: 1.5rem; /* md:text-4xl */
    }
  }
  
  
  /* Subtitle styles */
  .subtitle {
    font-size: 1rem;
  }
  
  @media (max-width: 768px) {
    .subtitle {
      font-size: 0.8rem; /* md:text-base */
    }
  }
  