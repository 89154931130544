.cta-web {
    width: 100%;
    height: 530px; /* Adjust this based on your desired footer height */
    background-color: #f8f9fa;
    background-image: url('/public/img/Light/webac-bg.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    justify-content: center;
    align-items: center;
    color: white;
    position: relative;
    padding: 60px 20px;
    text-align: center;
    
  }

  .ar{
    text-align: right !important;
    direction: rtl !important;
    font-family: 'Cairo';
    float: right;
  }
  .navr{
    text-align: center;
    padding: 10px 20px;
    flex: 1; /* Equal width for all links */
    white-space: nowrap; 
  }
  /* Background overlay */
.fixedcta {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
  background: rgba(0, 0, 0, 0.5);

}



  h3{
    font-weight: 500;
  }
  input{
    color: #243e2b;
  }
  .section-title {
    font-size: 2rem;
    color: #243e2b; /* Green text */
    font-weight: 500;
  }
  
  .section-title span {
    color: #3ab44c; /* Highlighted green */
  }
  
  .section-subtitle {
    margin: 10px 0 40px 0;
    color: #666;
    font-size: 1.2rem;
    max-width: unset !important;
  }
  
  .content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .text-column {
    flex: 1;
    padding-right: 30px;
    text-align: left;
  }
  
  .benefits-list {
    list-style-type: disc;
    padding-left: 20px;
    margin-top: 20px;
    color: #666;
    text-align: left;
  }
  
  .cta-buttons {
    margin-top: 30px;
  }
  
  .cta-btn {
    padding: 10px 20px;
    font-size: 1rem;
    margin-right: 20px;
    border: none;
    cursor: pointer;
  }
  
  .cta-primary {
    background-color: #3ab44c; /* Green */
    color: #fff;
  }
  
  .cta-secondary {
    background-color: transparent;
    color: #3ab44c;
    border: 2px solid #3ab44c;
  }
  
  .cta-btn:hover {
    opacity: 0.9;
  }
  
  .image-column {
    flex: 1;
    text-align: right;
  }
  
  .farm-image {
    width: 100%;
    max-width: 550px; /* Ensure it fits nicely */
  }
  @media (max-width: 760px) {
    .cta-web {
        height: auto; /* Allow height to adapt on smaller screens */
        background-size: cover; /* Change to 'contain' for smaller devices */
    }
    .cta-btn{
      font-size: 12px;
      margin-right: 5px;
   
      cursor: pointer;
      padding: 10px;
    }
  }
  @media (max-width: 768px) {
    .content-wrapper {
      flex-direction: column;
    }
    .navr{
      float: unset;
    }
    .ctah3{
      font-size: 16px !important;
    }
    .text-column {
    
      margin-bottom: 30px;
      text-align: center;
    }
    .image-column {
      text-align: center;
    }
    .cta-buttons {
      display: flex;
      justify-content: center;
    }
  }
  @media (max-width: 360px) {
    .cta-btn {
        font-size: 10px;
        margin-right: 5px;
        cursor: pointer;
        padding: 6px;
    }
}