/* EcosystemSection.css */

.ecosystem-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
}

.content {
  z-index: 10;
  position: relative;
}

.image-container {
  width: 100%;
  height: auto;
  opacity: 0;
  transition: opacity 3s ease;
  position: relative;
}

.image-container.fade-in {
  opacity: 1;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
