/* General Swiper container styling */
.mySwiper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mySwiper img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* Navigation button styling */
.swiper-button-next,
.swiper-button-prev {
  color: #10b981; /* Emerald color */
  font-size: 1.5rem;
  transition: color 0.3s;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  color: #047857; /* Darker emerald on hover */
}

/* Centered content styling */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

/* Icon styling */
.icon-wrapper {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d1fae5; /* Light emerald */
  border-radius: 50%;
  margin-bottom: 8px;
}

/* Responsive typography and layout adjustments */
h3 {
  font-size: 1.25rem;
  margin-bottom: 8px;
}

p {
  font-size: 1rem;
  text-align: center;
  margin-bottom: 12px;
  max-width: 500px;
}

/* Progress bar */
.progress-bar-container {
  width: 100%;
  height: 4px;
  background-color: #e5e7eb; /* Gray */
  position: relative;
}

.progress-bar {
  position: absolute;
  height: 100%;
  background-color: #10b981; /* Emerald */
  border-radius: 4px;
  animation: progress-animation 8.5s linear;
}

@keyframes progress-animation {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* Reverse progress bar for RTL */
.progress-bar-reverse {
  right: 0;
  animation: progress-animation-reverse 8.5s linear;
}

@keyframes progress-animation-reverse {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* Fullscreen image styling */
.fullscreen-image-container {
  position: absolute;
  top: 10%; /* Lower the image further on all screens */
  left: 0;
  width: 100%;
  z-index: 0;
  padding: 12rem 8rem; /* Default padding for larger screens */
}

.fullscreen-image-container img {
  width: 100%;
  height: auto;
  object-fit: cover;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
}

/* Responsive styling for tablets */
@media (max-width: 1024px) {
  .fullscreen-image-container {
    top: 15%; /* Lower the image a bit more on tablets */
    padding: 12rem 6rem; /* Slightly reduced padding */
  }
}

/* Responsive styling for mobile screens */
@media (max-width: 768px) {
  .fullscreen-image-container {
    top: 20%; /* Lower the image even more on smaller screens */
    padding: 10rem 1rem; /* Adjust padding for mobile */
  }
}


/* Responsive styles for different screen sizes */
@media (max-width: 1024px) {
  h3 {
    font-size: 1rem;
  }

  p {
    font-size: 0.875rem;
    max-width: 400px;
  }

  
}

@media (max-width: 768px) {
  

  h3 {
    font-size: 0.875rem;
  }

  p {
    font-size: 0.75rem;
  }

  .icon-wrapper {
    width: 48px;
    height: 48px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  
  .swiper-slide{
    align-items: center;
    justify-items: center;
    text-align: center;
  }

  h3 {
    font-size: 0.55rem;
    padding: 5px;
  }
  
  p {
    font-size: 0.625rem;
    padding: 5px;
  }

  .icon-wrapper {
    width: 40px;
    height: 40px;
  }
}

/* Hide buttons on medium and small screens */

@media (max-width: 768px) {
  .navigation-button {
    display: none;
  }
  /* .swiper-slide{
    width: unset !important;
  } */
}

@media (max-width: 360px) {

  /* .swiper-slide{
    width: unset!important;
  } */
}
