/* Base styles for the container */
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 1rem;
  padding-bottom: 1rem;
  margin-bottom: 10px;
}


@media (min-width: 768px) {
  .container {
    padding: 0;
  }
}


/* Heading styles */
.heading {
  max-width: 80rem;
  font-family: 'Sans-serif', Arial, sans-serif;
  font-weight: 600;
  
}

.heading span.green {
  color: #10b981; /* Emerald */
}

.heading span.black {
  color: #000; /* Black */
}

@media (max-width: 640px) {
  .heading {
    font-size: 1rem; /* sm:text-3xl */
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 1.5rem; /* md:text-4xl */
  }
}


/* Subtitle styles */
.subtitle {
  font-size: 1rem;
}

@media (max-width: 768px) {
  .subtitle {
    font-size: 0.8rem; /* md:text-base */
  }
}


/* Section styles */
.problem-cards-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem;
  
}

/* Cards container */
.cards-container {
  display: grid;
  gap: 1.5rem;
  max-width: 1200px;
  width: 100%;
  grid-template-columns: repeat(4, 1fr); /* Default to 4 cards */
}

/* Card styles */
.card {
  position: relative;
  width: 100%;
  max-width: 450px;
  height: 500px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  will-change: transform, box-shadow;
}

.card:hover {
  transform: translateY(-10px) scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Card image */
.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Permanent overlay */
.card-overlay-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15); /* Black overlay with slight transparency */
  z-index: 1; /* Ensures the overlay stays above the image */
}

/* Card overlay (hover effect) */
.card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background: rgba(0, 0, 0, 0.4);
  transition: opacity 0.3s ease-in-out;
  will-change: opacity;
}

/* Animation for the overlay content */
.card-overlay h3, 
.card-overlay p {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.4s ease-in-out, transform 0.3s ease-in-out;
}


.card:hover .card-overlay {
  opacity: 1;
}

.card:hover .card-overlay h3, 
.card:hover .card-overlay p {
  opacity: 1;
  transform: translateY(0);
}

/* Card title (non-hover state) */
.card-title {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
  color: #fff;
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  will-change: opacity;
}

/* On hover, hide the title outside hover */
.card:hover .card-title {
  opacity: 0;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .cards-container {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* 2 cards per row on medium screens */
  }
}

@media (max-width: 768px) {
  .cards-container {
    grid-template-columns: 1fr; /* 1 card per row on small screens */
  }

  .card-title {
    font-size: 1.2rem; /* Make title text larger */
  }
  .card-overlay p {
    font-size: 1rem; /* Increase overlay paragraph size */
  }
}

@media (max-width: 480px) {
  .card-title {
    font-size: 1.1rem; /* Ensure title is still visible */
  }
  .card-overlay p {
    font-size: 0.9rem; /* Adjust paragraph for small screens */
  }
  .card-overlay h3{
    font-size: 1rem;
  }
}
