h1, h2, h3, h4, h5, h6{
  font-family: unset !important;
}
.section-heading {
    font-size: 2rem;
    font-weight: bold;
  }
  
  .testimonial-text {
    font-size: 1.2rem;
    color: #343a40;
  }
  .section-subtitle{
    font-size: 14px;
  }
  .testimonial-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  
  .testimonial-arrows {
    font-size: 1.5rem;
    border-radius: 50%;
  }
  .slider-container {
  position: relative;
  width: 300px; 
  height: 600px; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.slide.active {
  transform: scale(1);
  opacity: 1;
  z-index: 2;
}

.slide:not(.active) {
  transform: scale(0.8);
  opacity: 0.5;
  z-index: 1;
}

