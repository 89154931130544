@tailwind base;
@tailwind components;
@tailwind utilities;

.rtl .swiper-container {
  direction: rtl;
}

.ltr .swiper-container {
  direction: ltr;
}

.rtl .swiper-slide2 {
  margin-left: 1rem;
  margin-right: 0;
}

.ltr .swiper-slide2 {
  margin-left: 0;
  margin-right: 1rem;
}




/* Custom Theme Colors */
:root {
  --background-color: #ffffff;
  --text-color: #000000;
  --primary-color: #50c878; /* Emerald */
  --secondary-color: #ffffff; /* White */
}

[data-theme="dark"] {
  --background-color: #121212;
  --text-color: #ffffff;
  --primary-color: #50c878; /* Emerald */
  --secondary-color: #ffffff; /* White */
}

/* Dark Mode Preference */
@media (prefers-color-scheme: dark) {
  :root {
    --background: #0a0a0a;
    --foreground: #ededed;
  }
}

/* Tailwind Base Styles */
@layer base {
  html {
    font-size: 18px; /* Standardizing font size */
    scroll-behavior: smooth;
  }
  body {
    color: var(--text-color);
    background-color: var(--background-color);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  
  
  /* Add this to your global CSS file or a component-specific CSS file */
  .map-background {
    position: absolute; /* Ensures the background covers the entire screen */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../public/img/Light/map.png"); /* Adjust the path to your map image */
    background-size: cover; /* Ensures the background covers the full screen */
    background-repeat: no-repeat;
    opacity: 0; /* Hidden by default */
    transition: opacity 0.5s ease-in-out; /* Smooth transition effect */
    pointer-events: none; /* Prevent the background from interfering with page interactions */
  }

  .map-background:hover {
    opacity: 1; /* Reveals the map when hovered */
  }


/* Problem Solving Section */

.swiper-container {
  width: 100%;
  
  direction: ltr; /* Swiper uses ltr internally by default; this ensures it doesn't conflict with outer rtl styling */
  
}

.swiper-container[dir="rtl"] {
  direction: rtl; /* Apply RTL when explicitly set */
}

.swiper-slide2 {
  width: auto; /* Flexibility based on content */
  max-width: calc(100% / 3 - 60px); /* Divide space equally, minus gaps */
  margin-inline-end: 20px; /* Use logical property for RTL/LTR support */
  flex-shrink: 0;
  direction: ltr; /* Default direction */
  
}

.swiper-slide2[dir="rtl"] {
  direction: rtl;

}












  



  .app {
    position: relative;
    z-index: 1; /* Ensures that your main content is on top of the background */
  }
}

/* Buttons */
@layer components {
  .btn-primary {
    @apply bg-primary-color text-secondary-color py-2 px-4 rounded-lg;
    transition: background-color 0.3s;
  }
  .btn-primary:hover {
    @apply bg-opacity-90;
  }

  .btn-secondary {
    @apply bg-secondary-color text-primary-color py-2 px-4 rounded-lg border border-primary-color;
  }
}

/* Headings */
@layer base {
  h1 {
    @apply text-4xl font-bold text-primary-color;
  }
  h2 {
    @apply text-3xl font-semibold;
  }
  h3 {
    @apply text-2xl;
  }
  
}

/* Links */
a {
  @apply text-black;
  transition: color 0.3s;
}
a:hover {
  @apply text-opacity-80 no-underline text-black;
}

/* Code Block Styling */
code {
  @apply font-mono bg-gray-200 text-gray-800 px-2 py-1 rounded;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes progress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
/* .animate-progress {
  animation: progress 2.4s linear forwards; 
} */

@keyframes progress-animation {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@keyframes progress-animation-reverse {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}