/* Custom Switch Styles */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .cardP{
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;

  
  }
  .card-bodyP{
    flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  }
  .pricing-card{
    height: 45%;
  }
  .pricing-card-ar{
    height: 40%;
    font-size: 15px;
    text-align: right;
    font-family: 'Cairo';
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #28a745;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  .price-row{
    width: 100%;
    max-width: unset;
    margin: auto;
  }
  @media (max-width: 480px) {
    .pricing-card{
      height: 39%;
    }
    .pricing-card-ar{
      height: 39%;
      font-size: 15px;
      text-align: right;
      font-family: 'Cairo';
    }
  }