/* For the container */
.responsive-container {
    position: absolute;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1.5rem; /* Default spacing between items */
    padding: 1rem; /* Default padding */
  }
  
  @media (min-width: 640px) {
    .responsive-container {
      gap: 2rem; /* Increase spacing on small screens */
      padding: 2rem;
    }
  }
  
  @media (min-width: 1024px) {
    .responsive-container {
      gap: 1.5rem; /* Increase spacing further on large screens */
      padding: 4rem;
    }
  }
  
  /* For the header */
  .responsive-header {
    font-weight: bold;
    color: white;
    font-size: 2rem; /* Default font size */
  }
  
  @media (min-width: 640px) {
    .responsive-header {
      font-size: 2.5rem; /* Small screens */
    }
  }
  
  @media (min-width: 1024px) {
    .responsive-header {
      font-size: 3rem; /* Large screens */
    }
  }
  
  /* For the text container */
  .responsive-text {
    padding: 1rem; /* Default padding */
    font-size: 1rem; /* Default font size */
  }
  
  @media (min-width: 640px) {
    .responsive-text {
      padding: 2rem;
      font-size: 1.25rem;
    }
  }
  
  @media (min-width: 1024px) {
    .responsive-text {
      padding: 4rem;
      font-size: 1.5rem;
    }
  }
  
  /* For the button */
  .responsive-button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 300;
    color: white;
    border: 2px solid white;
    border-radius: 0.75rem;
    transition: all 0.3s ease-in-out;
    background-color: transparent;
  }
  
  .responsive-button:hover {
    background-color: white;
    color: black;
    border-color: transparent;
  }
  
  @media (min-width: 640px) {
    .responsive-button {
      padding: 0.75rem 1.5rem;
      font-size: 1.125rem;
    }
  }
  